(function (root, factory) {
	if (typeof define === 'function' && define.amd) {
		define(['jquery'], factory);
	} else if (typeof module === 'object' && module.exports) {
		module.exports = factory(require('jquery'));
	} else {
		root.googleTagManager = factory($);
	}
}(this, function ($) {
	var googleTagManager = googleTagManager || {};

	googleTagManager.enviarEventoAnalytics = function (category, action, label) {
		if (window.dataLayer) {
			var data = {
				'CategoryGoogleAnalytics': category,
				'ActionGoogleAnalytics': action,
				'LabelGoogleAnalytics': label,
				'event': 'EventGoogleAnalytics'
			};
			window.dataLayer.push(data);
		}
	};

	googleTagManager.enviarEventoProductClick = function (codigoProdutoClicado) {
		if (window.dataLayer) {
			window.dataLayer.push({
				'codigoProdutoClicado': codigoProdutoClicado,
				'event': 'productClick'
			});
		}
	};

	googleTagManager.enviarEventoPromotionClick = function (codigoPromocaoClicada) {
		if (window.dataLayer) {
			window.dataLayer.push({
				'codigoPromocaoClicada': codigoPromocaoClicada,
				'event': 'promotionClick'
			});
		}
	};

	googleTagManager.enviarEventoCestaAtualizada = function() {
		if (window.dataLayer) {
			window.dataLayer.push({
				event: "cestaAtualizada"
			});
		}
	};

	googleTagManager.enviarEventoAddToCart = function (varsParaAdicionarNoDataLayer) {
		if (window.dataLayer) {
			varsParaAdicionarNoDataLayer = varsParaAdicionarNoDataLayer || {}
			var objeto = {};
			objeto['event'] = 'addToCart';
            
            Object.getOwnPropertyNames(varsParaAdicionarNoDataLayer).forEach(function (variavel) {
                objeto[variavel] = varsParaAdicionarNoDataLayer[variavel];
            });

			window.dataLayer.push(objeto);
		}
	};

	googleTagManager.adicionaPropriedadeAoDataLayer = function (propriedade) {
		if (window.dataLayer) {
			window.dataLayer.push(propriedade);
		}
	};

	return googleTagManager;
}));

define(function() {
	'use strict';

	return {
		
		monetario : function(n , d , t) {
			var c = 2,
			d = d || ",", 
			t =  t !== undefined ? t : ".",
			s = n < 0 ? "-" : "", 
			i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", 
			j = (j = i.length) > 3 ? j % 3 : 0;
			return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
		},
		
		cpf : function(str) {
			var size = str.length;
			if (size === 11) {
				return str.substr(0, 3) + '.' + str.substr(3, 3) + '.' + str.substr(6, 3) + '-' + str.substr(9, size - 9);
			}
			return str;
		},
		
		cnpj : function(str) {
			var size = str.length;
			if (size === 14) {
				return str.substr(0, 2) + '.' + str.substr(2, 3) + '.' + str.substr(5, 3) + '/' + str.substr(8, 4) + '-' + str.substr(12, size - 12);
			}
			return str;
		}

	};

});
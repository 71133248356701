define(function (require) {
    var _ = require('underscore');

    var enums = {};

    /* Formas pagamento */

    enums.formasPagamento = [
        {nome: 'boleto', codigo: 2, descricao: 'Boleto bancário'},
        {nome: 'cartao-credito', codigo: 1, descricao: 'Cartão de crédito'},
        {nome: 'carne', codigo: 5, descricao: 'Carnê Colombo'},
        {nome: 'pix', codigo: 20, descricao: 'Pix'},
    ];

    enums.getFormaPagamento = function (nome) {
        return _.find(enums.formasPagamento, function (forma) {
            return forma.nome === nome;
        });
    };

    enums.getFormaPagamentoByCodigo = function (codigo) {
        return _.find(enums.formasPagamento, function (forma) {
            return forma.codigo === codigo;
        });
    };

    /* Bandeiras cartão de crédito */

    enums.bandeirasCartaoCredito = [
        {nome: 'visa', codigo: 3},
        {nome: 'mastercard', codigo: 5},
        {nome: 'amex', codigo: 4},
        {nome: 'dinersclub', codigo: 19},
        {nome: 'aura', codigo: 41},
        {nome: 'hipercard', codigo: 45},
        {nome: 'elo', codigo: 98},
        {nome: 'hiper', codigo: 999}
    ];

    enums.getBandeiraCartaoCredito = function (codigo) {
        return _.find(enums.bandeirasCartaoCredito, function (bandeira) {
            return bandeira.codigo === parseInt(codigo);
        });
    };

    /* Situações pedido */

    enums.situacoesPedido = [
        {chave: 'PEDIDO_EFETUADO', descricao: 'Pedido efetuado', icone: 'pedido'},
        {chave: 'RESERVA_DE_CREDITO', descricao: 'Reserva de crédito', icone: 'pagamento'},
        {chave: 'CONFIRMANDO_DADOS', descricao: 'Confirmando dados', icone: 'confirmacao_dados'},
        {chave: 'DADOS_CONFIRMADOS', descricao: 'Dados confirmados', icone: 'confirmacao_dados'},
        {chave: 'AGUARDANDO_PAGAMENTO_BOLETO', descricao: 'Aguardando pagamento', icone: 'pagamento'},
        {chave: 'AGUARDANDO_PAGAMENTO_CARTAO', descricao: 'Aguardando pagamento', icone: 'pagamento'},
        {chave: 'AUTORIZACAO_DE_PAGAMENTO_NEGADA', descricao: 'Autorização de pagamento negada', icone: 'pagamento'},
        {chave: 'PAGAMENTO_CONFIRMADO', descricao: 'Pagamento confirmado', icone: 'pagamento'},
        {chave: 'SEPARANDO_PEDIDO', descricao: 'Separando pedido', icone: 'separando_pedido'},
        {chave: 'PROBLEMA_SEPARACAO_PEDIDO', descricao: 'Problema separação de pedido', icone: 'separando_pedido'},
        {chave: 'PEDIDO_SEPARADO', descricao: 'Pedido separado', icone: 'separando_pedido'},
        {chave: 'AGUARDANDO_FATURAMENTO', descricao: 'Aguardando faturamento', icone: 'nota_fiscal'},
        {chave: 'PROBLEMA_NO_FATURAMENTO_DO_PEDIDO', descricao: 'Problema no faturamento do pedido', icone: 'nota_fiscal'},
        {chave: 'PEDIDO_FATURADO', descricao: 'Pedido faturado', icone: 'nota_fiscal'},
        {chave: 'ENVIANDO_PARA_TRANSPORTADORA', descricao: 'Enviando para transportadora', icone: 'enviado_transportadora'},
        {chave: 'ENVIADO_PARA_TRANSPORTADORA', descricao: 'Enviado para transportadora', icone: 'enviado_transportadora'},
        {chave: 'EM_TRANSITO_PARA_LOCAL_DE_RETIRADA', descricao: 'Em trânsito para local de retirada', icone: 'transporte_e_saiu_entrega'},
        {chave: 'DISPONIVEL_NO_LOCAL_DE_RETIRADA', descricao: 'Disponível no local de retirada', icone: 'transporte_e_saiu_entrega'},
        {chave: 'PEDIDO_EM_TRANSITO', descricao: 'Pedido em trânsito', icone: 'transporte_e_saiu_entrega'},
        {chave: 'SAIU_PARA_ENTREGA', descricao: 'Saiu para entrega', icone: 'transporte_e_saiu_entrega'},
        {chave: 'PEDIDO_ENTREGUE', descricao: 'Pedido entregue', icone: 'entregue'},
        {chave: 'AGUARDANDO_RETIRADA', descricao: 'Aguardando retirada', icone: 'produto_retirado'},
        {chave: 'PEDIDO_RETIRADO', descricao: 'Pedido retirado', icone: 'produto_retirado'},
        {chave: 'PEDIDO_CANCELADO', descricao: 'Pedido cancelado', icone: 'pedido_cancelado'},
        {chave: 'PEDIDO_DEVOLVIDO', descricao: 'Pedido devolvido', icone: 'pedido_cancelado'},
        {chave: 'CONFIRMACAO_DE_DADOS_NEGADA', descricao: 'Confirmação de dados negada', icone: 'confirmacao_dados'},
        {chave: 'PEDIDO_DEVOLVIDO_PARCIALMENTE', descricao: 'Pedido devolvido parcialmente', icone: 'pedido_cancelado'},
        {chave: 'RESERVA_DE_CREDITO_NEGADA', descricao: 'Reserva de crédito ', icone: 'pagamento'},
    ];

    enums.getSituacaoPedido = function (chave) {
        return _.find(enums.situacoesPedido, function (situacao) {
            return situacao.chave === chave;
        });
    };

    /* Turno Entrega */

    enums.turnosEntrega = [
        {nome: 'Manhã', codigo: 1},
        {nome: 'Tarde', codigo: 2},
        {nome: 'Noite', codigo: 3}
    ];

    enums.getTurnoEntrega = function (codigo) {
        return _.find(enums.turnosEntrega, function (turno) {
            return turno.codigo === parseInt(codigo);
        });
    };

    /* Labels Botoes Workflow */

    enums.labelsBotoesWorkflow = [
        {chave: 'IMPRIMIR_NFE', label: 'Imprimir'},
        {chave: 'POLITICA_DA_RETIRADA', label: 'Política de retirada'},
        {chave: 'POLITICA_DA_ENTREGA', label: 'Política de entrega'},
        {chave: 'MAIS_DETALHES', label: 'Mais detalhes'},
        {chave: 'ACOMPANHAR_ENTREGA', label: 'Acompanhar Entrega'},

    ];

    enums.getlabelsBotoesWorkflow = function (chave) {
        return _.find(enums.labelsBotoesWorkflow, function (label) {
            return label.chave === chave;
        });
    };

    return enums;
});

define(function (require) {
    var enums = require('app/enums');
    var formatter = require('app/common/utils/Formatter');
    var googleTagManager = require('libs/googleTagManager');
    var TipoPagamento = require('app/common/domain/TipoPagamento');

    var helper = {
        enviarEventoAnalytics: function (category, action, label) {
            googleTagManager.enviarEventoAnalytics(category, action, label);
        },

        enviarEventoAddToCart: function (varsParaAdicionarNoDataLayer) {
            googleTagManager.enviarEventoAddToCart(varsParaAdicionarNoDataLayer);
        },

        atualizarCesta: function (cesta) {
            googleTagManager.adicionaPropriedadeAoDataLayer({
                cesta: this.converteCestaTagManager(cesta)
            });
            googleTagManager.enviarEventoCestaAtualizada();
        },

        getDimensionsPedido: function (cliente, cesta) {
            var dimensions = {};

            if (cliente.get('sexo')) {
                dimensions.sexoCliente = cliente.get('sexo').toUpperCase() === 'M' ? 'MASCULINO' : 'FEMININO';
            }
            if (cliente.get('dataNascimento')) {
                var dataNascimento = new Date(cliente.get('dataNascimento'));
                dimensions.anoNascimentoCliente = '' + dataNascimento.getFullYear();
            }


            dimensions.cidadeEstado = cesta.get('enderecoCidade') + ' - ' + cesta.get('enderecoEstado');
            dimensions.bairro = cesta.get('enderecoBairro');

            var codigoFormaPamento = cesta.get('codigoFormaPagamento');
            if (codigoFormaPamento === TipoPagamento.CARTAO_CREDITO || codigoFormaPamento === TipoPagamento.URA) {
                var bandeiraDoCartao = enums.getBandeiraCartaoCredito(cesta.get('codigoBandeira'));
                if (bandeiraDoCartao) {
                    dimensions.formaPagamento = bandeiraDoCartao.nome;
                }
            } else {
                dimensions.formaPagamento = enums.getFormaPagamentoByCodigo(codigoFormaPamento).descricao;
            }

            var parcelas = cesta.get('quantidadeParcelasCartao');
            if (parcelas) {
                dimensions.parcelas = parcelas;
            } else {
                dimensions.parcelas = '1';
            }

            var maxPrazoEntrega = cesta.get('prazoMaximo');
            dimensions.maxPrazoEntrega = '' + maxPrazoEntrega;
            dimensions.intervaloPrazoEntrega = this.getIntervaloPrazoEntrega(maxPrazoEntrega);

            return dimensions;
        },
        getIntervaloPrazoEntrega: function (diasMax) {
            var intervalo;
            if (diasMax <= 2) {
                intervalo = "1 - 2 dias";
            } else if (diasMax > 2 && diasMax <= 5) {
                intervalo = "3 - 5 dias";
            } else if (diasMax > 5 && diasMax <= 10) {
                intervalo = "6 - 10 dias";
            } else if (diasMax > 10 && diasMax <= 20) {
                intervalo = "11 - 20 dias";
            } else {
                intervalo = "21 ou mais dias";
            }

            return intervalo;
        },
        converteClienteTagManager: function (cliente) {
            var clienteRetorno = {};

            clienteRetorno.codigo = cliente.get('id');
            clienteRetorno.nome = cliente.get('nome');
            clienteRetorno.email = cliente.get('email');
            clienteRetorno.tipoPessoa = cliente.get('tipoPessoa');
            clienteRetorno.sexo = cliente.get('sexo');
            clienteRetorno.idade = cliente.get('idade');
            clienteRetorno.dataNascimento = cliente.get('dataNascimento');
            clienteRetorno.newsletter = cliente.get('permiteCampanhaMarketing');

            return clienteRetorno;
        },
        converteCestaTagManager: function (cesta) {
            var cestaRetorno = {};
            var produtos = cesta.get('itens');

            cestaRetorno.valorTotal = cesta.get('valorTotal');
            cestaRetorno.valorTotalFormatado = formatter.monetario(cesta.get('valorTotal'), ".", "");
            cestaRetorno.valorDesconto = formatter.monetario(cesta.get('valorDesconto'), ".", "");
            cestaRetorno.valorJurosFormatado = formatter.monetario(cesta.get('valorJuros'), ".", "");
            cestaRetorno.valorFreteFormatado = formatter.monetario(cesta.get('valorFrete'), ".", "");
            var produtosArray = [];

            if (produtos && produtos.length) {
                produtos.each(function (item) {
                    var produto = {};
                    var categoria = item.get('linhaDescricao') + ';' + item.get('familiaDescricao') + ';' + item.get('grupoDescricao');

                    produto.codigoSeller = item.get('codigoSeller');
                    produto.codigo = item.get('codigoProduto');
                    produto.codigoItem = item.get('codigoItem');
                    produto.descricao = item.get('nome');
                    produto.preco = formatter.monetario(item.get('precoPor'), ".", "");
                    produto.descontoTotal = item.get('descontoTotal');
                    produto.quantidade = item.get('quantidade');
                    produto.categoria = categoria;
                    produto.linha = item.get('linhaDescricao');
                    produto.empresa = item.get('empresa');
                    produto.tipo = item.get('id').substring(0, 2);
                    produto.caracteristicas = item.get('caracteristicas');
                    produtosArray.push(produto);
                });
            }

            cestaRetorno.produtos = JSON.stringify(produtosArray);
            return cestaRetorno;
        },
        convertePedidoTagManager: function (detalhePedido) {
            var pedidoRetorno = {};

            pedidoRetorno.codigo = detalhePedido.get('codigoPedidoPrincipal');
            pedidoRetorno.valorTotal = detalhePedido.get('valorTotal');
            pedidoRetorno.valorDesconto = detalhePedido.get('valorDesconto');
            pedidoRetorno.valorFrete = detalhePedido.get('valorFrete');
            pedidoRetorno.valorTotalFormatado = formatter.monetario(detalhePedido.get('valorTotal'), ".", "");
            pedidoRetorno.valorJurosFormatado = formatter.monetario(detalhePedido.get('valorJuros'), ".", "");
            pedidoRetorno.valorFreteFormatado = formatter.monetario(detalhePedido.get('valorFrete'), ".", "");
            pedidoRetorno.entregaCep = detalhePedido.get('entregaCep');
            pedidoRetorno.quantidadeParcelas = detalhePedido.get('quantidadeParcelas');
            pedidoRetorno.cupomDesconto = detalhePedido.get('cupomDesconto');
            pedidoRetorno.produtos = [];

            detalhePedido.get('informacoesPedido').each(function (info) {
                info.get('itensPedido').each(function (item) {
                    var produto = {};

                    if (item.get('codigoProduto')) {
                        var categoria = item.get('linhaDescricao') + ';' + item.get('familiaDescricao') + ';' + item.get('grupoDescricao');

                        produto.codigo = item.get('codigoProduto');
                        produto.codigoItem = item.get('codigoItem');
                        produto.descricao = item.get('nome');
                        produto.preco = formatter.monetario(item.get('preco'), ".", "");
                        produto.descontoTotal = item.get('descontoTotal');
                        produto.quantidade = item.get('quantidade');
                        produto.codigoLinha = item.get('codigoLinha');
                        produto.codigoGrupo = item.get('codigoGrupo');
                        produto.categoria = categoria;
                        produto.linha = item.get('linhaDescricao');
                        produto.empresa = item.get('empresa');
                        produto.caracteristicas = item.get('caracteristicas');

                        pedidoRetorno.produtos.push(produto);
                    }
                });
            });

            pedidoRetorno.qntItensPedido = pedidoRetorno.produtos.length;

            return pedidoRetorno;
        },
        converteProdutoSugestaoCesta: function (item) {
            var produto = item.collection.parent;
            var preco = produto.get('precoFormatado');

            return this.converteProdutoSugestao(item, produto, preco);
        },
        converteProdutoSugestaoModalCesta: function (item) {
            var preco = item.get('precoFormatado');
            var produto = item.collection.parent;

            return this.converteProdutoSugestao(item, produto, preco);
        },
        converteProdutoSugestao: function (item, produto, preco) {
            var codigoItem = item.get('codigo') || item.get('codigoItem');
            var disponivel = '' + item.get('disponivel');
            var codigoSeller = '' + item.get('codigoSeller');

            var codigoProduto = '' + produto.get('id');
            var descricaoProduto = produto.get('descricao');
            var linhaProduto = produto.get('linha');
            var familiaProduto = produto.get('familia');
            var grupoProduto = produto.get('grupo');
            var categoria = [linhaProduto, familiaProduto, grupoProduto].join(';');

            return {
                'descricao': descricaoProduto,
                'codigo': codigoProduto,
                'codigoItem': codigoItem,
                'preco': preco,
                'disponivel': disponivel,
                'categoria': categoria,
                'codigoSeller': codigoSeller
            };
        }
    };

    return helper;
});

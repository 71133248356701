define(function () {

	var Cookie = Cookie || {
		
		set: function (name, value, daysToExpire) {
			var expires = "";

			daysToExpire = (daysToExpire) ? daysToExpire : 365 * 10;
			var secondsToExpire = (daysToExpire * 24 * 60 * 60 * 1000);
			var date = new Date();
			date.setTime(date.getTime() + secondsToExpire);
			expires = "expires=" + date.toUTCString();

			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},
		
		read: function ( name ) {
			var targetCookie = name + "=";
			var cookies = document.cookie.split(';');
			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				while (cookie.charAt(0) === ' ') {
					cookie = cookie.substring(1);
				}
				if (cookie.indexOf(targetCookie) === 0) {
					return cookie.substring(targetCookie.length, cookie.length);
				}
			}
			return "";
		},
		
		erase: function ( name ) {
			this.set( name, "", -1 );
		},
		
		isSet: function ( name ) {
			var cookieValue = this.read( name );
			return ( cookieValue ? true : false );
		}
	};
	
	return Cookie;
});	
define(function () {
    var cookie = require('libs/cookie');
    var GoogleTagManagerHelper = require('app/common/utils/GoogleTagManagerHelper');

    var modoExibicao = modoExibicao || {};

    var GRADE = 'grade';
    var LISTA = 'lista';
    var MODO_EXIBICAO = 'modo_exibicao_produtos';

    var idDivProdutoVitrine = '#produtos-vitrine';
    var classCssProdutoLista = 'produtos-lista';

    var exibirProdutosEmModoGrade = function (triggerGTM) {
        $('.modo-listagem-lista').removeClass('active');
        $('.modo-listagem-tabela').addClass('active');

        $(idDivProdutoVitrine).removeClass(classCssProdutoLista);

        var gradeCss = $('#menu-lateral').val();
        $(idDivProdutoVitrine).addClass(gradeCss);
        if (triggerGTM) {
            GoogleTagManagerHelper.enviarEventoAnalytics('Listagem de Produtos', 'Alterou modo de listagem', 'Modo Grade');
        }
    };

    var exibirProdutosEmModoLista = function (triggerGTM) {
        $('.modo-listagem-tabela').removeClass('active');
        $('.modo-listagem-lista').addClass('active');

        $(idDivProdutoVitrine).addClass(classCssProdutoLista);

        var gradeCss = $('#menu-lateral').val();
        $(idDivProdutoVitrine).removeClass(gradeCss);
        if (triggerGTM) {
            GoogleTagManagerHelper.enviarEventoAnalytics('Listagem de Produtos', 'Alterou modo de listagem', 'Modo Lista');
        }
    };

    var inicializar = function () {
        if (cookie.isSet(MODO_EXIBICAO)) {
            modoExibicao = cookie.read(MODO_EXIBICAO);

            if (modoExibicao === GRADE) {
                exibirProdutosEmModoGrade();
            } else {
                exibirProdutosEmModoLista();
            }
        } else {
            exibirProdutosEmModoGrade();
            cookie.set(MODO_EXIBICAO, GRADE);
        }
    };

    // Modo exibição em Grade
    $("span[id^='exibicao-modo-grade']").click(function () {
        exibirProdutosEmModoGrade(true);
        cookie.set(MODO_EXIBICAO, GRADE);
    });

    // Modo exibição em Lista
    $("span[id^='exibicao-modo-lista']").click(function () {
        exibirProdutosEmModoLista(true);
        cookie.set(MODO_EXIBICAO, LISTA);
    });

    inicializar();

    return modoExibicao;
});

